<template>
  <Component
    :is="tag"
    :href="href"
    :to="route"
    class="tt-text-body-2 d-inline-block tt-text-body-2 my-4"
  >
    <VIcon
      size="14"
      color="tt-light-blue"
      class="mr-2"
    >
      fal fa-angle-left
    </VIcon> {{ text }}
  </Component>
</template>

<script>

export default {
  name: 'BackButton',
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    tag() {
      if (typeof this.to === 'string') {
        return 'a';
      }
      return 'RouterLink';
    },
    href() {
      if (typeof this.to === 'string') {
        return this.to;
      }
      return undefined;
    },
    route() {
      if (typeof this.to === 'object') {
        return this.to;
      }
      return undefined;
    },
  },
};
</script>
