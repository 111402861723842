
import errorHandler from '@/helpers/errorHandler';
import BackButton from '@/layouts/app/BackButton.vue';
import RobotGptBox from '@/components/analytics/yandexGPT/RobotGptBox.vue';
import RobotGptError from '@/components/analytics/yandexGPT/RobotGptError.vue';
import MarkdownRenderer from '@/components/analytics/yandexGPT/MarkdownRenderer.vue';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesYandexGptWeakness,
  V1EntitiesUsersPublicShowUser,
} from '@/services/api/tsxass';
import { EngagementAbility } from '@/helpers/constants';
import { mapGetters, mapState } from 'vuex';
import UserAvatar from '@/components/common/UserAvatar.vue';
import ExternalLinkDialog from '@/components/common/ExternalLinkDialog.vue';
import GptMethodologyBanner from '@/components/analytics/yandexGPT/GptMethodologyBanner.vue';

export default {
  name: 'YandexGpt',
  components: {
    BackButton,
    RobotGptBox,
    RobotGptError,
    MarkdownRenderer,
    UserAvatar,
    ExternalLinkDialog,
    GptMethodologyBanner,
  },
  inject: ['RouteNames'],
  data() {
    return {
      surveyName: '',
      loading: false,
      newGeneration: false,
      retry: false,
      isFailure: false,
      timeout: null as ReturnType<typeof setTimeout> | null,
      response: null as V1EntitiesYandexGptWeakness | null,
      currentTab: 0,
      user: null as V1EntitiesUsersPublicShowUser | null,
    };
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    ...mapState('engagementInfo', {
      abilities: 'abilities',
    }),
    backTo() {
      return {
        name: this.RouteNames.R_APP_SURVEY_ANALYTICS,
        params: {
          surveyId: String(this.surveyId),
        },
        query: {
          userId: this.userId,
          tab: this.$route.query?.tab,
        },
      };
    },
    factors() {
      return this.response?.response || [];
    },
    canRunYandexGpt() {
      return this.can(EngagementAbility.CAN_RUN_YANDEX_GPT);
    },
    surveyId() {
      return Number(this.$route.params.surveyId);
    },
    userId() {
      return String(this.$route.query.userId);
    },
    tab() {
      return String(this.$route.query.tab);
    },
    isRunAgainDisabled() {
      return this.response?.userOverheated || this.response?.companyBalance < 0 || this.loading;
    },
    runAgainButtonText() {
      return this.loading ? this.$t('yandexGPT.waitForАnalysis') : this.$t('yandexGPT.runAgain');
    },
    isResponseError() {
      return this.isFailure || (!this.loading && !this.response);
    },
    errorText() {
      if (this.response?.companyBalance < 0) {
        return this.$t('yandexGPT.noCompanyTokensAvailable');
      }
      if (this.response?.userOverheated) {
        return this.$t('yandexGPT.userOverheated');
      }
      return '';
    },
  },

  mounted() {
    this.fetchProfile();
    this.fetchPrompt();
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    onRunAgainRetryClick() {
      this.response = null;
      this.newGeneration = false;
      this.retry = true;
      this.fetchPrompt();
    },
    onRunAgainClick() {
      this.response = null;
      this.newGeneration = true;
      this.retry = false;
      this.fetchPrompt();
    },
    async fetchProfile() {
      const { data } = await tsxassApi.getV1UsersId(this.userId);
      this.user = data?.user || null;
    },
    async fetchPrompt() {
      try {
        this.loading = true;
        const { data } = await tsxassApi.postV1YandexGptWeakness({
          surveyId: this.surveyId,
          userId: this.userId,
          newGeneration: this.newGeneration,
          retry: this.retry,
        });
        this.response = data;
      } catch (error) {
        errorHandler(error);
        this.loading = false;
        this.response = null;
      } finally {
        this.newGeneration = false;
        this.retry = false;
        this.loading = false;
        this.isFailure = false;
        if (this.response && this.response.status !== 'finished') {
          if (this.response.status === 'failure') {
            this.response = null;
            this.isFailure = true;
          } else {
            this.loading = true;
            this.timeout = setTimeout(() => { this.fetchPrompt(); }, 3000);
          }
        }
      }
    },
    async onExternalLinkClick(link: string) {
      const gptExternalLinkDialogDoNotShow = Boolean(localStorage.getItem('gptExternalLinkDialogDoNotShow'));
      const openLink = (href: string) => {
        const url = new URL(href);
        window.open(url.href, '_blank');
      };

      if (gptExternalLinkDialogDoNotShow) {
        openLink(link);
        return;
      }

      const res = await this.$refs.externalLinkConfirm.show();
      if (res) {
        openLink(link);
      }
    },
  },
};
