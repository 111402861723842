<template>
  <TTActionDialog
    v-model="isVisible"
    :width="width"
    :closable="hasClose"
    @input="onCloseClick"
  >
    <div class="d-flex justify-center mb-n3">
      <VImg
        :src="require('@/assets/img/exclamationSigns.png')"
        height="170"
        contain
        class="mt-n5"
      />
    </div>
    <p class="tt-text-subtitle mb-2">
      {{ $t('externalLinkDialog.title') }}
    </p>
    <p class="tt-text-body-2">
      {{ $t('externalLinkDialog.subtitle') }}
    </p>

    <TTCheckbox
      :input-value="notShowDialog"
      :label="$t('externalLinkDialog.notShowAgain')"
      class="pt-2 mb-2"
      @change="onCheckboxClick"
    />

    <template #actions>
      <TTBtn
        color="tt-primary"
        large
        text
        data-test-label="buttonConfirm"
        @click="onConfirm"
      >
        {{ $t('externalLinkDialog.confirmText') }}
      </TTBtn>
      <TTBtn
        color="tt-primary"
        large
        data-test-label="buttonCancel"
        @click="onCancel"
      >
        {{ $t('externalLinkDialog.cancelText') }}
      </TTBtn>
    </template>
  </TTActionDialog>
</template>

<script>
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';

export default {
  name: 'ExternalLinkDialog',

  components: {
    TTActionDialog,
  },

  props: {
    width: {
      type: [Number, String],
      default: 396,
    },
    cancelOnClose: {
      type: Boolean,
      default: false,
    },
    hasClose: {
      type: Boolean,
      default: true,
    },
    payload: undefined,
  },

  data() {
    return {
      isVisible: false,
      promise: null,
      resolve: null,
      notShowDialog: false,
    };
  },

  created() {
    this.notShowDialog = Boolean(localStorage.getItem('gptExternalLinkDialogDoNotShow'));
  },

  methods: {
    onCancel() {
      if (this.resolve) {
        this.resolve(false);
        this.isVisible = false;
      }
    },
    onConfirm() {
      if (this.resolve) {
        const payload = this.payload === undefined ? true : this.payload;
        this.resolve(payload);
        this.isVisible = false;
      }
    },
    onCloseClick() {
      if (this.cancelOnClose) {
        this.onCancel();
      }
    },

    /**
     * Публичный метод
     * Открывает диалог и возвращает промис с результатом выбора пользователя (false - "Cancel", true - "Confirm")
     */
    show() {
      this.promise = new Promise((resolve) => {
        this.resolve = resolve;
      });

      this.isVisible = true;
      return this.promise;
    },

    onCheckboxClick(value) {
      if (value) {
        localStorage.setItem('gptExternalLinkDialogDoNotShow', value);
      } else {
        localStorage.removeItem('gptExternalLinkDialogDoNotShow');
      }
    },
  },
};
</script>
