<template>
  <div
    class="banner d-flex rounded-lg tt-light-blue pale overflow-hidden"
  >
    <VImg
      v-if="showImage"
      :src="require('@/assets/img/gptMethodologyBanner.png')"
      class="mr-1"
      position="right"
      width="226px"
    />
    <div class="d-flex justify-center align-center flex-grow-1">
      <div class="tt-text-body-2 flex-grow-1 mx-4 my-3">
        {{ $t('gptMethodologyBanner.bannerText') }}
      </div>

      <TTBtn
        class="mr-4"
        :href="href"
        target="_blank"
      >
        {{ $t('gptMethodologyBanner.buttonText') }}
      </TTBtn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GptMethodologyBanner',
  props: {
    showImage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line
      href: 'https://storage.yandexcloud.net/engagement-prod/manuals/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F%20Yandex%20GPT.pdf',
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  max-height: 88px;
}
</style>
